import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './Home.css';

const Home = () => {
  const [currentInput, setCurrentInput] = useState('');
  const [operator, setOperator] = useState(null);
  const [previousInput, setPreviousInput] = useState('');
  const [result, setResult] = useState('0');

  const updateDisplay = (newInput) => {
    setCurrentInput(newInput);
    updateResult(newInput);
  };

  const clearDisplay = () => {
    setCurrentInput('');
    setOperator(null);
    setPreviousInput('');
    setResult('0');
  };

  const deleteLast = () => {
    const newInput = currentInput.slice(0, -1);
    updateDisplay(newInput);
  };

  const inputNumber = (num) => {
    const newInput = currentInput + num;
    updateDisplay(newInput);
  };

  const inputOperator = (op) => {
    if (currentInput === '' && op === '-') {
      updateDisplay('-');
      return;
    }
    if (currentInput === '') return;
    setOperator(op);
    setPreviousInput(currentInput);
    updateDisplay(currentInput + op);
  };

  const inputDot = () => {
    if (!currentInput.includes('.') || /[\+\-\*\/]$/.test(currentInput)) {
      updateDisplay(currentInput + '.');
    }
  };

  const updateResult = (expression) => {
    if (/[+\-*/%]$/.test(expression)) {
      expression = expression.slice(0, -1);
    }
    try {
      const resultValue = eval(expression) || '0';
      setResult(resultValue.toString());
    } catch {
      setResult('0');
    }
  };

  const calculate = () => {
    updateResult(currentInput);
    setCurrentInput(result);
    setOperator(null);
    setPreviousInput('');
  };

  return (
    <div className="container">
      <Helmet>
        <title>Free online Calculator - All-in-One Calculator</title>
        <meta name="description" content="Welcome to Omnifronthub, your go-to source for all kinds of calculators, including a standard calculator for everyday use. Explore our fast, accurate, and easy-to-use calculator!" />
        <meta name="keywords" content="calculator, online calculator, all-in-one calculator, basic calculator, " />
      </Helmet>

      <h1>Online Calculator- free online Calculator for basic math calculations</h1>
      <p>all-in-one calculator.</p>

      <h2>Why Choose Our Calculator?</h2>
      <p>Our calculator is fast, accurate, and easy to use. Whether you need basic calculations or complex operations, We are providing you the best calculator for your daily need</p>

      <div className="calculator">
        <div className="display" id="inputDisplay">{currentInput || '0'}</div>
        <div className="result" id="resultDisplay">{result}</div>
        <div className="buttons">
          <div className="button operator" onClick={clearDisplay}>AC</div>
          <div className="button operator" onClick={() => inputOperator('%')}>%</div>
          <div className="button operator" onClick={deleteLast}>←</div>
          <div className="button operator" onClick={() => inputOperator('/')}>/</div>
          <div className="button" onClick={() => inputNumber(7)}>7</div>
          <div className="button" onClick={() => inputNumber(8)}>8</div>
          <div className="button" onClick={() => inputNumber(9)}>9</div>
          <div className="button operator" onClick={() => inputOperator('*')}>*</div>
          <div className="button" onClick={() => inputNumber(4)}>4</div>
          <div className="button" onClick={() => inputNumber(5)}>5</div>
          <div className="button" onClick={() => inputNumber(6)}>6</div>
          <div className="button operator" onClick={() => inputOperator('-')}>-</div>
          <div className="button" onClick={() => inputNumber(1)}>1</div>
          <div className="button" onClick={() => inputNumber(2)}>2</div>
          <div className="button" onClick={() => inputNumber(3)}>3</div>
          <div className="button operator" onClick={() => inputOperator('+')}>+</div>
          <div className="button" onClick={() => inputNumber(0)}>00</div>
          <div className="button" onClick={() => inputNumber(0)}>0</div>
          <div className="button" onClick={inputDot}>.</div>
          <div className="button operator" onClick={calculate}>=</div>
        </div>
      </div>
    </div>
  );
};

export default Home;
