import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy Of Omnifronthub</h1>
      <p>Policy updated on 06/09/2024</p>

      <p>At Omnifronthub, accessible from "/https://omnifronthub.com/", one of our main priorities is the privacy of our visitors. This Privacy Policy document contains the types of information collected and recorded by Omnifronthub and how we use it.</p>

      <h2>1. Information We Collect</h2>
      <p>Omnifronthub focuses on providing users with various calculators, including but not limited to fuel cost calculators, normal calculators, and others. The information we collect may include:</p>
      <ul>
        <li><strong>Personal Information</strong>: When you use features such as the contact form, we may collect personal details such as your name, email address, and phone number. This data is only used to respond to inquiries and provide customer support.</li>
        <li><strong>Usage Data</strong>: We may collect data on how you interact with our calculators, which can include IP addresses, browser types, and usage patterns. This helps us improve our services.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>The information collected by Omnifronthub is used in the following ways:</p>
      <ul>
        <li>To operate and maintain our website, ensuring our calculators work as intended.</li>
        <li>To improve, personalize, and expand our services.</li>
        <li>To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website.</li>
        <li>To understand and analyze how you use our site.</li>
      </ul>

      <h2>3. Cookies</h2>
      <p>Omnifronthub uses cookies to store information about visitors’ preferences, to record user-specific information on which pages of the site are accessed or visited, and to personalize or customize our web page content based on visitors' browser types or other information.</p>
      <p>You can choose to disable cookies through your browser options.</p>

      <h2>4. Third-Party Services</h2>
      <p>We may use third-party services like Google Analytics to track visitor interactions and help us analyze how our website is used. These third-party services may also collect information about you through cookies and similar technologies.</p>
      <p>Omnifronthub integrates Google Analytics, and for more information on how Google uses your data, visit the <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Google Privacy Policy</a>.</p>

      <h2>5. Data Sharing</h2>
      <p>We do not sell or trade your personal information to third parties. However, we may share your data in the following situations:</p>
      <ul>
        <li>With service providers that help us operate our website.</li>
        <li>To comply with legal obligations.</li>
        <li>To protect our rights and property.</li>
      </ul>

      <h2>6. Data Security</h2>
      <p>Omnifronthub takes the security of your personal information seriously. We implement appropriate security measures to safeguard your data. However, no transmission of data over the internet is completely secure, and we cannot guarantee the absolute security of any information.</p>

      <h2>7. Your Rights</h2>
      <p>As a user of Omnifronthub, you have the right to:</p>
      <ul>
        <li>Access, update, or delete your personal information that we have collected.</li>
        <li>Opt out of data tracking services like Google Analytics by adjusting your browser settings.</li>
      </ul>

      <h2>8. Contact Information</h2>
      <p>If you have any questions or concerns about this Privacy Policy or your data, feel free to contact us through our <a href="/contact-us">Contact Us page</a></p>
    </div>
  );
};

export default PrivacyPolicy;
