import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import './App.css';
import Home from './Home';
import FuelCostCalculator from './FuelCostCalculator';
import AdSenseRevenueCalculator from './AdSenseRevenueCalculator';
import PrivacyPolicy from './PrivacyPolicy';
import ContactUs from './ContactUs';
import About from './About';
import Blogs from './Blogs';
import ConsentBanner from './ConsentBanner';
import AllCalculators from './AllCalculators';
import FinancialCalculators from './FinancialCalculators'; // New component for Financial Calculators
import HealthAndFitnessCalculators from './HealthAndFitnessCalculators'; // New component for Health and Fitness Calculators
import MathCalculators from './MathCalculators'; // New component for Math Calculators
import OtherCalculators from './OtherCalculators'; // New component for Other Calculators
import NotFound from './NotFound'; // Import NotFound component

// Import logo
import logo from './logo.png';

// Google Analytics tracking
function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-XXGN9ER122', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);
}

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <Router>
      <header className="header">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="title">All-in-One Calculator</div>
        <button className={`menu-toggle ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <nav className={`nav ${menuOpen ? 'visible' : ''}`}>
          <ul>
            <li><Link to="/" onClick={() => setMenuOpen(false)}>Home</Link></li>
            <li><Link to="/fuel-cost-calculator" onClick={() => setMenuOpen(false)}>Fuel Cost Calculator</Link></li>
            <li><Link to="/adsense-revenue-calculator" onClick={() => setMenuOpen(false)}>AdSense Revenue Calculator</Link></li>
            <li><Link to="/all-calculators" onClick={() => setMenuOpen(false)}>All Calculators</Link></li>
            <li><Link to="/privacy-policy" onClick={() => setMenuOpen(false)}>Privacy Policy</Link></li>
            <li><Link to="/contact-us" onClick={() => setMenuOpen(false)}>Contact Us</Link></li>
            <li><Link to="/about" onClick={() => setMenuOpen(false)}>About</Link></li>
            <li><Link to="/blogs" onClick={() => setMenuOpen(false)}>Blogs</Link></li>
          </ul>
        </nav>
      </header>
      <main>
        <ConsentBanner />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/fuel-cost-calculator" element={<FuelCostCalculator />} />
          <Route path="/adsense-revenue-calculator" element={<AdSenseRevenueCalculator />} />
          <Route path="/all-calculators" element={<AllCalculators />} />
          <Route path="/financial-calculators" element={<FinancialCalculators />} />
          <Route path="/health-fitness-calculators" element={<HealthAndFitnessCalculators />} />
          <Route path="/math-calculators" element={<MathCalculators />} />
          <Route path="/other-calculators" element={<OtherCalculators />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about" element={<About />} />
          <Route path="/blogs" element={<Blogs />} />
          {/* Add NotFound Route for unmatched URLs */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
    </Router>
  );
}

export default App;
