import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './AllCalculators.css';

const AllCalculators = () => {
  return (
    <div className="all-calculators-page">
      <Helmet>
        <title>All Calculators - Omnifronthub</title>
        <meta name="description" content="Explore a comprehensive range of calculators, including financial, health, math, and more." />
      </Helmet>
      <h1 className="page-heading">All Calculators</h1>
      <div className="categories">
        <div className="category">
          <h2><Link to="/financial-calculators">Financial Calculators</Link></h2>
          <ul>
            <li><Link to="/financial-calculators/mortgage-calculator">Mortgage Calculator</Link></li>
            <li><Link to="/financial-calculators/loan-calculator">Loan Calculator</Link></li>
            {/* Add more subcategories */}
          </ul>
        </div>
        <div className="category">
          <h2><Link to="/health-fitness-calculators">Health and Fitness Calculators</Link></h2>
          <ul>
            <li><Link to="/health-fitness-calculators/bmi">BMI Calculator</Link></li>
            {/* Add more subcategories */}
          </ul>
        </div>
        <div className="category">
          <h2><Link to="/math-calculators">Math Calculators</Link></h2>
          <ul>
            <li><Link to="/math-calculators/scientific">Scientific Calculator</Link></li>
            {/* Add more subcategories */}
          </ul>
        </div>
        <div className="category">
          <h2><Link to="/other-calculators">Other Calculators</Link></h2>
          <ul>
            <li><Link to="/other-calculators/age">Age Calculator</Link></li>
            {/* Add more subcategories */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AllCalculators;
