import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import './AdSenseRevenueCalculator.css';

const baseEarnings = {
  'Arts & Entertainment': {
    Americas: 900,
    'Asia and Pacific Countries': 1000,
    'Europe, Middle East and Africa': 900,
  },
  'Autos & Vehicles': {
    Americas: 1500,
    'Asia and Pacific Countries': 1300,
    'Europe, Middle East and Africa': 1300,
  },
  'Beauty & Fitness': {
    Americas: 1500,
    'Asia and Pacific Countries': 1200,
    'Europe, Middle East and Africa': 1800,
  },
  'Books & Literature': {
    Americas: 700,
    'Asia and Pacific Countries': 400,
    'Europe, Middle East and Africa': 600,
  },
  'Business & Industrial': {
    Americas: 2000,
    'Asia and Pacific Countries': 1900,
    'Europe, Middle East and Africa': 1300,
  },
  'Computers & Electronics': {
    Americas: 2600,
    'Asia and Pacific Countries': 2400,
    'Europe, Middle East and Africa': 1500,
  },
  Finance: {
    Americas: 2000,
    'Asia and Pacific Countries': 3100,
    'Europe, Middle East and Africa': 2500,
  },
  'Food & Drink': {
    Americas: 1500,
    'Asia and Pacific Countries': 1100,
    'Europe, Middle East and Africa': 1100,
  },
  Games: {
    Americas: 700,
    'Asia and Pacific Countries': 900,
    'Europe, Middle East and Africa': 1100,
  },
  Health: {
    Americas: 2000,
    'Asia and Pacific Countries': 1900,
    'Europe, Middle East and Africa': 1900,
  },
  'Hobbies & Leisure': {
    Americas: 1600,
    'Asia and Pacific Countries': 1400,
    'Europe, Middle East and Africa': 1600,
  },
  'Home & Garden': {
    Americas: 8000,
    'Asia and Pacific Countries': 2400,
    'Europe, Middle East and Africa': 3000,
  },
  'Internet & Telecom': {
    Americas: 1600,
    'Asia and Pacific Countries': 1800,
    'Europe, Middle East and Africa': 1400,
  },
  'Jobs & Education': {
    Americas: 1800,
    'Asia and Pacific Countries': 1000,
    'Europe, Middle East and Africa': 1100,
  },
  'Law & Government': {
    Americas: 1800,
    'Asia and Pacific Countries': 1800,
    'Europe, Middle East and Africa': 1500,
  },
  News: {
    Americas: 500,
    'Asia and Pacific Countries': 900,
    'Europe, Middle East and Africa': 500,
  },
  'Online Communities': {
    Americas: 1000,
    'Asia and Pacific Countries': 2800,
    'Europe, Middle East and Africa': 1000,
  },
  'People & Society': {
    Americas: 1000,
    'Asia and Pacific Countries': 900,
    'Europe, Middle East and Africa': 800,
  },
  'Pets & Animals': {
    Americas: 1400,
    'Asia and Pacific Countries': 1500,
    'Europe, Middle East and Africa': 1300,
  },
  'Real Estate': {
    Americas: 1600,
    'Asia and Pacific Countries': 1700,
    'Europe, Middle East and Africa': 1200,
  },
  Reference: {
    Americas: 1400,
    'Asia and Pacific Countries': 1000,
    'Europe, Middle East and Africa': 1100,
  },
  Science: {
    Americas: 1400,
    'Asia and Pacific Countries': 1200,
    'Europe, Middle East and Africa': 1000,
  },
  Shopping: {
    Americas: 1600,
    'Asia and Pacific Countries': 1500,
    'Europe, Middle East and Africa': 1700,
  },
  Sports: {
    Americas: 600,
    'Asia and Pacific Countries': 900,
    'Europe, Middle East and Africa': 600,
  },
  'Travel & Transportation': {
    Americas: 1700,
    'Asia and Pacific Countries': 1200,
    'Europe, Middle East and Africa': 1900,
  },
};

const AdSenseRevenueCalculator = () => {
  const [category, setCategory] = useState('none');
  const [region, setRegion] = useState('none');
  const [pageViews, setPageViews] = useState('');
  const [earnings, setEarnings] = useState(null);
  const earningsRef = useRef(null); // Ref to earnings result area

  useEffect(() => {
    if (category !== 'none' && region !== 'none' && pageViews > 0) {
      const baseAmount = baseEarnings[category][region];
      const earningsPerView = baseAmount / 50000; // calculate earnings per view for 50,000 page views
      const calculatedEarnings = earningsPerView * pageViews;
      setEarnings(`$${Math.round(calculatedEarnings)}`);
    } else {
      setEarnings(null);
    }
  }, [category, region, pageViews]);

  useEffect(() => {
    if (earningsRef.current) {
      earningsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [earnings]);

  return (
    <div className="adsense-calculator-container">
      <Helmet>
        <title>Google Adsense Revenue Calculator</title>
        <meta name="description" content="Estimate your earnings with our Google Adsense Revenue Calculator. Find out how much you can earn from ads on your website." />
        <meta name="keywords" content="Google Adsense, Revenue Calculator, Adsense Earnings, Website Monetization" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Google Adsense Revenue Calculator" />
        <meta property="og:description" content="Estimate your earnings with our Google Adsense Revenue Calculator. Find out how much you can earn from ads on your website." />
        <meta property="og:image" content="/path-to-image.jpg" />
        <meta property="og:url" content="https://your-website-url.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Google Adsense Revenue Calculator" />
        <meta name="twitter:description" content="Estimate your earnings with our Google Adsense Revenue Calculator. Find out how much you can earn from ads on your website." />
        <meta name="twitter:image" content="/path-to-image.jpg" />
      </Helmet>
      <h1 className="adsense-calculator-heading">Google Adsense Revenue Calculator</h1>
      <div className="adsense-heading-line"></div>
      <p className="adsense-calculator-description">
        Check how much you can earn from Google Adsense with our revenue calculator.
      </p>
      <p className="adsense-calculator-message">
        Select the category and region from where you want to check the earnings from your website.
      </p>
      <div className="adsense-dropdown-container">
        <select
          className="adsense-category-dropdown adsense-dropdown"
          aria-label="Category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="none" disabled hidden>Category</option>
          {Object.keys(baseEarnings).map(cat => (
            <option key={cat} value={cat}>{cat}</option>
          ))}
        </select>
        <select
          className="adsense-region-dropdown adsense-dropdown"
          aria-label="Regions"
          value={region}
          onChange={(e) => setRegion(e.target.value)}
        >
          <option value="none" disabled hidden>Regions</option>
          <option value="Americas">Americas</option>
          <option value="Asia and Pacific Countries">Asia and Pacific Countries</option>
          <option value="Europe, Middle East and Africa">Europe, Middle East and Africa</option>
        </select>
      </div>
      <div className="adsense-input-container">
        <input
          className="adsense-input"
          type="number"
          placeholder="Enter monthly page views"
          value={pageViews}
          onChange={(e) => setPageViews(e.target.value)}
        />
      </div>
      <div className="adsense-earnings-result" ref={earningsRef}>
        {earnings && (
          <p>Your estimated earnings for the selected category and region is <strong>{earnings}</strong></p>
        )}
      </div>
    </div>
  );
};

export default AdSenseRevenueCalculator;
