import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-page">
      <h1>About Us - Omnifronthub</h1>
      <p>
        Welcome to Omnifronthub, your all-in-one hub for a comprehensive collection of calculators designed to simplify your everyday decisions. Whether you need to manage your finances, plan your fitness goals, or solve complex mathematical problems, Omnifronthub provides you with the right tools. We believe in empowering users by offering a wide range of calculators to suit different needs.
      </p>

      <h2>At Omnifronthub, we offer:</h2>

      <div className="calculator-category">
        <h3>Financial Calculators</h3>
        <p>From personal finance to investment, our financial calculators help you make informed decisions with ease:</p>
        <ul>
          <li>Mortgage Calculator, Loan Calculator, Auto Loan Calculator</li>
          <li>Investment Calculator, Retirement Calculator, Amortization Calculator</li>
          <li>Tax Calculators like Income Tax, Estate Tax, VAT, and Sales Tax Calculators</li>
          <li>Specialized tools such as Debt Payoff Calculator, Savings Calculator, and ROI Calculator</li>
        </ul>
      </div>

      <div className="calculator-category">
        <h3>Fitness & Health Calculators</h3>
        <p>Track your fitness journey with precision:</p>
        <ul>
          <li>BMI Calculator, Calorie Calculator, Body Fat Calculator</li>
          <li>Pregnancy Calculators: Pregnancy, Ovulation, and Due Date Calculators</li>
          <li>Nutrition & Exercise tools: TDEE Calculator, Pace Calculator, and Calories Burned Calculator</li>
        </ul>
      </div>

      <div className="calculator-category">
        <h3>Math Calculators</h3>
        <p>For students, professionals, and anyone tackling daily math problems:</p>
        <ul>
          <li>Scientific Calculator, Fraction Calculator, Percentage Calculator</li>
          <li>Geometry tools like Triangle Calculator, Circle Calculator, and Pythagorean Theorem Calculator</li>
          <li>Probability and Statistics: Permutation and Combination, Z-Score, and Confidence Interval Calculators</li>
        </ul>
      </div>

      <div className="calculator-category">
        <h3>Other Useful Calculators</h3>
        <p>Need something more specific? We've got you covered:</p>
        <ul>
          <li>Date and Time Calculators, GPA Calculator, Fuel Cost Calculator</li>
          <li>Engineering tools like Voltage Drop Calculator, Horsepower Calculator, and Ohms Law Calculator</li>
          <li>And even fun tools like Love Calculator, Dice Roller, and Password Generator</li>
        </ul>
      </div>

      <h2>Why Choose Omnifronthub?</h2>
      <p>
        We combine simplicity with precision. Our calculators are designed to be user-friendly, ensuring you get accurate results without the hassle. Whether you’re planning for the future, analyzing data, or simply solving everyday problems, our calculators are here to save you time and effort.
      </p>
      <p>
        With Omnifronthub, you’re always just a few clicks away from the answers you need!
      </p>
      <p className="cta-text">Explore our world of calculators today and simplify your life, one calculation at a time.</p>
    </div>
  );
};

export default About;
