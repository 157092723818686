import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './FuelCostCalculator.css';

const FuelCostCalculator = () => {
  const [distance, setDistance] = useState('');
  const [efficiency, setEfficiency] = useState('');
  const [price, setPrice] = useState('');
  const [result, setResult] = useState('');

  const [distanceUSD, setDistanceUSD] = useState('');
  const [efficiencyUSD, setEfficiencyUSD] = useState('');
  const [priceUSD, setPriceUSD] = useState('');
  const [resultUSD, setResultUSD] = useState('');

  const calculateFuelCostINR = () => {
    const fuelNeeded = (distance / 100) * efficiency;
    const cost = fuelNeeded * price;
    setResult(`This trip will require ${fuelNeeded.toFixed(2)} liters of fuel, which amounts to a fuel cost of ₹${cost.toFixed(2)}.`);
  };

  const calculateFuelCostUSD = () => {
    const fuelNeeded = (distanceUSD / 100) * efficiencyUSD;
    const cost = fuelNeeded * priceUSD;
    setResultUSD(`This trip will require ${fuelNeeded.toFixed(2)} liters of fuel, which amounts to a fuel cost of $${cost.toFixed(2)}.`);
  };

  return (
    <>
      <Helmet>
        <title>Fuel Cost Calculator - INR & USD</title>
        <meta name="description" content="Calculate your fuel cost for trips in Indian Rupees and U.S. Dollars with our easy-to-use fuel cost calculator." />
        <meta name="keywords" content="fuel cost calculator, fuel price in INR, fuel price in USD, trip cost calculator, vehicle fuel efficiency" />
      </Helmet>
      <div className="fuel-cost-container">
        <h1>Fuel Cost Calculator</h1>
        <div className="calculator-container">
          {/* INR Calculator */}
          <div className="fuel-calculator">
            <h2>Calculate in Indian Rupees (₹)</h2>
            <label>Trip Distance (km):</label>
            <input type="number" value={distance} onChange={(e) => setDistance(e.target.value)} />
            <label>Fuel Efficiency (L/100 km):</label>
            <input type="number" value={efficiency} onChange={(e) => setEfficiency(e.target.value)} />
            <label>Fuel Price (₹/L):</label>
            <input type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
            <button onClick={calculateFuelCostINR}>Calculate</button>
            {result && <div className="result-INR">{result}</div>}
          </div>

          {/* USD Calculator */}
          <div className="fuel-calculator">
            <h2>Calculate in U.S. Dollars ($)</h2>
            <label>Trip Distance (km):</label>
            <input type="number" value={distanceUSD} onChange={(e) => setDistanceUSD(e.target.value)} />
            <label>Fuel Efficiency (L/100 km):</label>
            <input type="number" value={efficiencyUSD} onChange={(e) => setEfficiencyUSD(e.target.value)} />
            <label>Fuel Price ($/L):</label>
            <input type="number" value={priceUSD} onChange={(e) => setPriceUSD(e.target.value)} />
            <button onClick={calculateFuelCostUSD}>Calculate</button>
            {resultUSD && <div className="result-USD">{resultUSD}</div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default FuelCostCalculator;
