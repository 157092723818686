import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './ConsentBanner.css'; // Assuming you have a CSS file for styling the banner

const ConsentBanner = () => {
  const [showBanner, setShowBanner] = useState(true);

  const handleAccept = () => {
    // You might want to save this choice in local storage or cookies
    setShowBanner(false);
  };

  return (
    showBanner && (
      <div className="consent-banner">
        <p>
          We use cookies to improve your experience on our site. By continuing to use our site, 
          you accept our use of cookies. Read our <Link to="/privacy-policy">Privacy Policy</Link> 
          for more information.
        </p>
        <button onClick={handleAccept}>Accept</button>
      </div>
    )
  );
};

export default ConsentBanner;
