import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
  return (
    <div className="notfound-container">
      <div className="notfound-content">
        <h1 className="notfound-heading">404</h1>
        <h2 className="notfound-subheading">Oops! Page Not Found</h2>
        <p className="notfound-message">
          The page you're looking for might have been removed, had its name changed, or is temporarily unavailable.
        </p>
        <Link to="/" className="notfound-home-link">
          Back to Home
        </Link>
        <div className="notfound-animated-elements">
          <div className="notfound-circle circle1"></div>
          <div className="notfound-circle circle2"></div>
          <div className="notfound-circle circle3"></div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
